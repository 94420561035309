import { useState } from 'react';
import './App.scss';
import FatalError from './modules/fatalError/FatalError';


function App() {
  //Any error which occurs, anywhere in the process, that is fatal
  const [fatalError, setFatalError] = useState<string | null>(null);

  return (
      <div className="App">
        { fatalError &&
          <FatalError errorText={ fatalError } />

        }
      </div>
  );
}

export default App;
