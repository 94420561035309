import { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyBkiYTK4sudza4o0CSUFb0AWgPEY5gmgZw",
    authDomain: "cloudcmd-test-01.firebaseapp.com",
    projectId: "cloudcmd-test-01",
    storageBucket: "cloudcmd-test-01.appspot.com",
    messagingSenderId: "537414142970",
    appId: "1:537414142970:web:b3127b46056a520587b243"
};

export default firebaseConfig;